<template>
    <div class="contact_div">
        <div class="nav_div"></div>
        <div class="contact_content_div">
            <div class="content_all_box  allContentWidth">
                <div class="content_left">
                    <div class="content_left_div">
                        <!-- <p>分公司</p> -->
                        <ul class="content_left_ul">
                            <li v-for="item in tabArr" :key="item.id" :class="{active:curActive==item.id}"
                                @click="tabClickFun(item.id)">
                                <span>{{ item.name }}</span>
                                <div style="width:27px;">
                                    <span :class="{right_bottom_jt:curActive==item.id}" class=""
                                          v-if="curActive==item.id">
                                        <img :src="imgSrc[1]"/>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content_right">
                    <div class="right_top_line"></div>
                    <div class="address_box">
                        <p class="title_p">{{ contentObj.address_title }}：</p>
                        <p class="content_p">{{ contentObj.address_text }}</p>
                    </div>
                    <div class="address_box" v-if="contentObj.tel_text!==''">
                        <p class="title_p">{{ contentObj.tel_title }}：</p>
                        <p class="content_p"><b class="icon_div"><img :src="imgSrc[2]"/></b>{{ contentObj.tel_text }}
                        </p>
                    </div>
                    <div class="address_box" v-if="contentObj.fax_text!==''">
                        <p class="title_p">{{ contentObj.fax_title }}：</p>
                        <p class="content_p"><b class="icon_div"><img :src="imgSrc[3]"/></b>{{ contentObj.fax_text }}
                        </p>
                    </div>
                    <div class="address_box" v-if="contentObj.email_text!==''">
                        <p class="title_p">{{ contentObj.email_title }}：</p>
                        <p class="content_p"><b class="icon_div"><img :src="imgSrc[4]"/></b>{{ contentObj.email_text }}
                        </p>
                    </div>
                    <div class="address_box" v-if="contentObj.mobile_text!==''">
                        <p class="title_p">{{ contentObj.mobile_title }}：</p>
                        <p class="content_p"><b class="icon_div"><img :src="imgSrc[5]"/></b>{{ contentObj.mobile_text }}
                        </p>
                    </div>
                    <div class="address_box" v-if="contentObj.general_line_text!==''">
                        <p class="title_p">{{ contentObj.general_line_title }}：</p>
                        <p class="content_p"><b class="icon_div"><img
                                :src="imgSrc[6]"/></b>{{ contentObj.general_line_text }}</p>
                    </div>
                </div>
            </div>
            <div class="little_content_all_box">
                <Collapse v-model="value2" accordion>
                    <Panel v-for="item in contentArr" :key="item.id" :name="item.name">
                        <span>{{ item.name }}</span>
                        <div slot="content">
                            <div class="address_box">
                                <p class="title_p">{{ item.address_title }}：</p>
                                <p class="content_p">{{ item.address_text }}</p>
                            </div>
                            <div class="address_box" v-if="item.tel_text!==''">
                                <p class="title_p">{{ item.tel_title }}：</p>
                                <p class="content_p"><b class="icon_div"><img :src="imgSrc[2]"/></b>{{ item.tel_text }}
                                </p>
                            </div>
                            <div class="address_box" v-if="item.fax_text!==''">
                                <p class="title_p">{{ item.fax_title }}：</p>
                                <p class="content_p"><b class="icon_div"><img :src="imgSrc[3]"/></b>{{ item.fax_text }}
                                </p>
                            </div>
                            <div class="address_box" v-if="item.email_text!==''">
                                <p class="title_p">{{ item.email_title }}：</p>
                                <p class="content_p"><b class="icon_div"><img
                                        :src="imgSrc[4]"/></b>{{ item.email_text }}</p>
                            </div>
                            <div class="address_box" v-if="item.mobile_text!==''">
                                <p class="title_p">{{ item.mobile_title }}：</p>
                                <p class="content_p"><b class="icon_div"><img
                                        :src="imgSrc[5]"/></b>{{ item.mobile_text }}</p>
                            </div>
                            <div class="address_box" v-if="item.general_line_text!==''">
                                <p class="title_p">{{ item.general_line_title }}：</p>
                                <p class="content_p"><b class="icon_div"><img
                                        :src="imgSrc[6]"/></b>{{ item.general_line_text }}</p>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {
        this.tabClickFun(this.curActive);
    },
    watch: {},
    methods: {
        tabClickFun(id) {
            this.curActive = id;
            this.contentArr.forEach((item) => {
                if (id === item.id) {
                    this.contentObj = item;
                }
            })
        },
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(0)
        imgSrc.push(require('../../assets/img/contact/right_bottom1.png'));//(1)
        imgSrc.push(require('../../assets/img/contact/tel_icon.png'));//(2)
        imgSrc.push(require('../../assets/img/contact/fax_icon.png'));//(3)
        imgSrc.push(require('../../assets/img/contact/email_icon.png'));//(4)
        imgSrc.push(require('../../assets/img/contact/mobile.png'));//(5
        imgSrc.push(require('../../assets/img/contact/dianhua.png'));//(6)
        //  imgSrc.push(require('../../assets/img/contact/address.png'));//(7)
        return {
            imgSrc: imgSrc,
            curActive: 1,
            value2: '1',
            tabArr: [
                {id: 1, name: '上海总部'},
                {id: 2, name: '香港财务中心'},
                {id: 3, name: '大连'},
                {id: 4, name: '佛山'},
                {id: 5, name: '广州'},
                {id: 6, name: '宁波'},
                {id: 7, name: '青岛'},
                {id: 8, name: '深圳'},
                {id: 9, name: '天津'},
                {id: 10, name: '厦门'},
                // {id: 19, name: '台湾'},
                {id: 11, name: 'Vietnam - Hochiminh'},
                {id: 12, name: 'Vietnam - Hanoi'},
                {id: 13, name: 'Malaysia - Port Kelang'},
                {id: 14, name: 'Malaysia - Penang'},
                {id: 15, name: 'Thailand - Bangkok'},
                {id: 20, name: 'Thailand - Songkhla'},
                {id: 16, name: 'Korea - Seoul'},
                {id: 17, name: 'Singapore'},
                {id: 18, name: 'Indonesia'},
            ],
            contentObj: {},
            contentArr: [
                {
                    id: 1,
                    name: '上海总部',
                    address_title: '地址',
                    address_text: '上海市黄浦区中山南路28号久事大厦8楼DEF室',
                    tel_title: '电话',
                    tel_text: '+86-021-63230022',
                    fax_title: '传真',
                    fax_text: '+86-021-63235587/5597',
                    email_title: '邮箱',
                    email_text: 'office_sha@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 2,
                    name: '香港财务中心',
                    address_title: '地址',
                    address_text: '香港九龙城区九龙城鸿图道22号俊汇中心1807室',
                    tel_title: '电话',
                    tel_text: '+852 31685080',
                    fax_title: '传真',
                    fax_text: '+852 31685080',
                    email_title: '邮箱',
                    email_text: 'office_hkg@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 3,
                    name: '大连',
                    address_title: '地址',
                    address_text: '大连市中山区人民路71号成大大厦1608室',
                    tel_title: '电话',
                    tel_text: '+86-411-82827672',
                    fax_title: '传真',
                    fax_text: '+86-411-82827673',
                    email_title: '邮箱',
                    email_text: 'office_dlx@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 4,
                    name: '佛山',
                    address_title: '地址',
                    address_text: '佛山市禅城区石湾街道汾江南路金源街8号(国贸大厦)315室',
                    tel_title: '电话',
                    tel_text: '+86-757-83276721',
                    fax_title: '传真',
                    fax_text: '',
                    email_title: '邮箱',
                    email_text: '',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 5,
                    name: '广州',
                    address_title: '地址',
                    address_text: '广州市越秀区中山二路3号13楼C室',
                    tel_title: '电话',
                    tel_text: '+86-020-83490618',
                    fax_title: '传真',
                    fax_text: '+86-020-83488918',
                    email_title: '邮箱',
                    email_text: 'office_can@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 6,
                    name: '宁波',
                    address_title: '地址',
                    address_text: '宁波市海曙区灵桥路777号中国人寿大厦1706室',
                    tel_title: '电话',
                    tel_text: '+86-0574-27662788',
                    fax_title: '传真',
                    fax_text: '+86-0574-27669139',
                    email_title: '邮箱',
                    email_text: 'office_ngb@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 7,
                    name: '青岛',
                    address_title: '地址',
                    address_text: '青岛市市南区香港中路10号颐和国际A2607',
                    tel_title: '电话',
                    tel_text: '+86-532-68888262',
                    fax_title: '传真',
                    fax_text: '',
                    email_title: '邮箱',
                    email_text: 'office_tao@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 8,
                    name: '深圳',
                    address_title: '地址',
                    address_text: '深圳市罗湖区深南东路5002号信兴广场地王大厦4708室',
                    tel_title: '电话',
                    tel_text: ' +86-0755-82770059/83953601',
                    fax_title: '传真',
                    fax_text: '+86-0755-83226113',
                    email_title: '邮箱',
                    email_text: 'office_szx@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 9,
                    name: '天津',
                    address_title: '地址',
                    address_text: '天津市和平区南京路219号天津中心写字楼1606室',
                    tel_title: '电话',
                    tel_text: '+86-022-23394339',
                    fax_title: '传真',
                    fax_text: '+86-022-23393755',
                    email_title: '邮箱',
                    email_text: 'office_tsn@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 10,
                    name: '厦门',
                    address_title: '地址',
                    address_text: '厦门思明区后埭溪路28号皇达大厦19楼BC单元',
                    tel_title: '电话',
                    tel_text: '+86-592-5185258',
                    fax_title: '传真',
                    fax_text: '+86-592-5185259',
                    email_title: '邮箱',
                    email_text: 'office_xmn@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 11,
                    name: 'Vietnam - Hochiminh',
                    address_title: 'Address',
                    address_text: 'Unit 601, 6TH Floor, No. 45 Vo Thi Sau Streeet, Dakao Ward, District 1, Ho Chi Minh City',
                    tel_title: 'Tel',
                    tel_text: '(028) 36365725/ 26/ 27',
                    fax_title: 'Fax',
                    fax_text: '',
                    email_title: 'Email',
                    email_text: 'office_hcm@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 12,
                    name: 'Vietnam - Hanoi',
                    address_title: 'Address',
                    address_text: '10TH Floor, No. 7 Ton That Thuyet Street, Cau Giay Distric, Hanoi, Vietnam',
                    tel_title: 'Tel',
                    tel_text: '+84 24 32047262',
                    fax_title: 'Fax',
                    fax_text: '',
                    email_title: 'Email',
                    email_text: 'office_han@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 13,
                    name: 'Malaysia - Port Kelang',
                    address_title: 'Address',
                    address_text: 'UNIT N-08-01, LEVEL 8, FIRST SUBANG NORTH TOWER, JALAN SS15/4G, 47500 SUBANG JAYA, SELANGOR DARUL EHSAN.',
                    tel_title: 'Tel',
                    tel_text: '+012-345 6083 (Michael Low)',
                    fax_title: 'Fax',
                    fax_text: '+603 3324 1485',
                    email_title: 'Email',
                    email_text: 'office_pkg@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '+6012-6651683',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 14,
                    name: 'Malaysia - Penang',
                    address_title: 'Address',
                    address_text: 'Unit 18-32-F, Menara Gurney, Persiaran Gurney, 10250 Pulau Pinang, Malaysia',
                    tel_title: 'Tel',
                    tel_text: '+604-2941990 / 1992',
                    fax_title: 'Fax',
                    fax_text: '',
                    email_title: 'Email',
                    email_text: 'office_pen@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '+6017-5508203',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 15,
                    name: 'Thailand - Bangkok',
                    address_title: 'Address',
                    address_text: 'Unit 1003 Floor 10th , Sathorn Prime Building. 26 Naradhiwas-Rajanagarindra Road, Yannawa, Sathorn, 10120, Bangkok',
                    tel_title: 'Tel',
                    tel_text: '+66-63-492-3649',
                    fax_title: 'Fax',
                    fax_text: '',
                    email_title: 'Email',
                    email_text: 'office_thai@integratedlm.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 16,
                    name: 'Korea - Seoul',
                    address_title: 'Address',
                    address_text: 'Rm 805, SamJung Bldg,74 Sejong-daero, Jung-gu, Seoul, 04526, Korea',
                    tel_title: 'Tel',
                    tel_text: '+82-2-774 4983',
                    fax_title: 'Fax',
                    fax_text: '+82-2-774-9484',
                    email_title: 'Email',
                    email_text: 'office_kr@eastrongintl.com',
                    mobile_title: 'Mobile',
                    mobile_text: '+82-10-8500-7192',
                    general_line_title: 'General Line',
                    general_line_text: '',
                },
                {
                    id: 17,
                    name: 'Singapore',
                    address_title: 'Address',
                    address_text: '20 Harbour Drive, #07-01, 117612, Singapore',
                    tel_title: 'Tel',
                    tel_text: '+65-62526300',
                    fax_title: 'Fax',
                    fax_text: '',
                    email_title: 'Email',
                    email_text: 'office_sin@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                {
                    id: 18,
                    name: 'Indonesia',
                    address_title: 'Address',
                    address_text: 'Citra Towers Kemayoran building North tower , 15th floor , Unit J Jl. Benyamin Suaeb Kav A6, Kemayoran, Jakarta Pusat 10630',
                    tel_title: 'Tel',
                    tel_text: '+628174765893',
                    fax_title: 'Fax',
                    fax_text: '',
                    email_title: 'Email',
                    email_text: 'office_idn@eastrongintl.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
                // {
                //     id: 19,
                //     name: '台湾',
                //     address_title: '地址',
                //     address_text: '114665 台北市內湖區瑞光路76巷67號3樓',
                //     tel_title: '电话',
                //     tel_text: '(886) 939-058-866',
                //     fax_title: '传真',
                //     fax_text: '',
                //     email_title: '邮箱',
                //     email_text: 'caven.chen@eastrongintl.com',
                //     general_line_title: 'General Line',
                //     general_line_text: '',
                //     mobile_title: 'Mobile',
                //     mobile_text: ''
                // },
                {
                    id: 20,
                    name: 'Thailand - Songkhla',
                    address_title: 'Address',
                    address_text: '607 K.H. Building. 6th Floor, Unit 6B, Petchkasem Road, Hat Yai, Hat Yai, Songkhla 90110',
                    tel_title: 'Tel',
                    tel_text: '+66 63 492 3649',
                    fax_title: 'Fax',
                    fax_text: '',
                    email_title: 'Email',
                    email_text: 'office_thai@integratedlm.com',
                    general_line_title: 'General Line',
                    general_line_text: '',
                    mobile_title: 'Mobile',
                    mobile_text: ''
                },
            ],
        }
    }
}
</script>
<style lang="less">
.contact_div {
  ::-webkit-scrollbar { //整个滚动条的样式
    width: 10px;
    background-color: #E9EDF2;
  }

  /*定义滚动条轨道 内阴影+圆角*/

  ::-webkit-scrollbar-track { //此属性是设置滚动条的滚动区域的样式即滚动条的轨道
    // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #E9EDF2;
  }

  /*定义滑块 内阴影+圆角*/

  ::-webkit-scrollbar-thumb { //次属性是设置滚动条可拖拽部分的样式即滚动条的滑块
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(2, 2, 2, 0.4);

    // background-color:#555;

  }

  .contact_content_div {
    // background: #E9EDF2;
    height: auto;

    .content_all_box {
      padding: 40px 0 20px 0;
      display: flex;

      .content_left {
        background: #2C2E32;
        height: auto;
        width: 50%;
        color: #fff;
        background: linear-gradient(-135deg, transparent 35px, #2C2E32 0);
        // background: linear-gradient(-45deg, transparent 35px, #000 0) bottom right;
        // background:
        // linear-gradient(-135deg, transparent 0px, #150d36 0) top left,
        // linear-gradient(45deg, transparent 0px, #150d36 0) bottom left,
        // linear-gradient(-45deg, transparent 35px, #150d36 0) bottom right,
        // linear-gradient(-135deg, transparent 35px, #150d36 0) top right;
        // background-repeat: no-repeat;
        // background-size: 50% 50%;
        display: flex;
        justify-content: center;
        padding: 30px 0 50px 0;
        max-height: 600px;
        overflow-y: auto;
        direction: rtl; // rtl 表示从右到左，

        .content_left_div {
          width: 60%;
          line-height: 56px;
          direction: ltr; //ltr 表示从左到右。
          .content_left_ul {
            padding-bottom: 50px;

            li {
              cursor: pointer;
              font-size: 20px;
              position: relative;
            }

            li:hover::after {
              width: 100%;
            }

            li::after {
              transition: all 0.6s;
              position: absolute;
              content: "";
              bottom: 0px;
              left: 0px;
              width: 0px;
              height: 3px;
              background: #4E27FF;

              .right_bottom_jt {
                display: inline-block;
                width: 17px;
                height: 17px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .right_bottom_jt {
              display: inline-block;
              width: 27px;
              height: 27px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .content_left_ul .active {
            font-weight: 700;
            font-size: 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &::after {
              width: 100%;
            }
          }
        }
      }

      .content_right {
        width: 50%;
        padding: 50px 0 0 60px;

        .right_top_line {
          width: 100%;
          height: 3px;
          background: #666;
        }

        .address_box {
          line-height: 30px;
          margin-top: 20px;
          border-bottom: 1px solid #B2B2B2;

          .title_p {
            color: #A6A6A6;
          }

          .content_p {
            font-size: 18px;
            color: #333333;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .icon_div {
              width: 25px;
              height: 25px;
              display: inline-block;
              margin-right: 10px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .little_content_all_box {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .contact_div {
    .contact_content_div {
      .content_all_box {
        display: none;
      }

      .little_content_all_box {
        display: block;

        .address_box {
          line-height: 30px;
          margin-top: 20px;
          border-bottom: 1px solid #B2B2B2;

          .title_p {
            color: #A6A6A6;
          }

          .content_p {
            font-size: 14px;
            color: #333333;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .icon_div {
              width: 25px;
              height: 25px;
              display: inline-block;
              margin-right: 10px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
